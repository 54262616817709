import React from "react";
import "./Escenarios.css";
import ReactFlow, {
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";

function Escenarios() {
  const sampleData = {
    answersDirectFuture: [
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "f836cecb-d3d4-46f3-8bae-b05ee0fa840c",
        question: "Puesto ambicioso directo 1",
        categoria: "",
        answer: "uh",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "c0ae3178-084b-4347-b4fc-c210cbe82bf5",
        question: "Puesto ambicioso directo 2",
        categoria: "",
        answer: "iuhiu",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "5259864a-eb48-43df-9f87-9b9dc77e8fba",
        question: "Puesto ambicioso directo 3",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "c36e03f7-ce10-4b87-86c5-3c6d0889bf64",
        question: "Puesto directo 1",
        categoria: "",
        answer: "hiu",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "820641a2-6da3-458c-be9a-e51c2ecb3918",
        question: "Puesto directo 2",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "cd191167-52ec-47b2-a571-910e73c34558",
        question: "Puesto directo 3",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "b9fe5529-e99b-4166-a4c8-0decbc495720",
        question: "Puesto directo 4",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "befe6766-36d4-4964-bee5-00272a14b2c5",
        question: "Puesto directo 5",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "4156f9d9-e27a-42f3-834b-cd78564082ae",
        question: "Puesto directo 6",
        categoria: "",
        answer: "hiu",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "72ce351f-e2c1-4503-ada1-b27911767e87",
        question: "Puesto directo 8",
        categoria: "",
        answer: "iu",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "0f824b44-e2f0-4ab4-b6dc-908b8213d6c0",
        question: "Puesto directo 7",
        categoria: "",
        answer: "hiuh",
        json: null,
        created_at: "2024-07-02 17:21:30.463",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
    ],
    answersAdjacentFuture: [
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "d4d96374-ddc1-46f0-928e-574a055ed6a0",
        question: "Puesto ambicioso adyacente 1",
        categoria: "",
        answer: "ijiu",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "cc8bd90d-38e6-4f07-9c76-a2aba5e05cc4",
        question: "Puesto ambicioso adyacente 2",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "82c3f7d9-f058-46a0-b37f-3d625e055dda",
        question: "Puesto ambicioso adyacente 3",
        categoria: "",
        answer: "huih",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "c6316d7b-e86f-497f-93e5-8aa5a1cc5a0a",
        question: "Puesto adyacente 1",
        categoria: "",
        answer: "iuh",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "49226db7-05a0-488a-a28d-eec40adc17e1",
        question: "Puesto adyacente 2",
        categoria: "",
        answer: "iuh",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "b7b09a29-328c-4761-9736-ad48f45ada05",
        question: "Puesto adyacente 3",
        categoria: "",
        answer: "uih",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "30803dd0-74b4-4bde-979e-758dcd352798",
        question: "Puesto adyacente 5",
        categoria: "",
        answer: "uihui",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "bd4ed1cf-7ec6-432f-8fcd-c5f70b3f19fa",
        question: "Puesto adyacente 7",
        categoria: "",
        answer: "hiuh",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "4fa226dd-9abc-4d69-8bce-4f63af2cd4ca",
        question: "Puesto adyacente 8",
        categoria: "",
        answer: "uihui",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "ca27da00-83fe-4338-9653-3ea08658f50f",
        question: "Puesto adyacente 4",
        categoria: "",
        answer: "iuh",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "5a9a6478-31a4-43b9-aeb7-bd6eddb8cf9d",
        question: "Puesto adyacente 6",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:37.255",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
    ],
    answersSecondAdjacentFuture: [
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "69bb8e0a-193d-4519-9217-1821de8397aa",
        question: "Puesto ambicioso adyacente 1",
        categoria: "",
        answer: "iji",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "95028547-2c3c-4374-a3b9-78e536e42afa",
        question: "Puesto ambicioso adyacente 2",
        categoria: "",
        answer: "ui",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "b481f5df-f80d-412d-9abe-01df8e32b82a",
        question: "Puesto ambicioso adyacente 3",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "5633b185-d71e-4499-b6d9-86f5a584b7dd",
        question: "Puesto adyacente 1",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "1866a6ea-c9e5-4564-a4a7-f37525001c03",
        question: "Puesto adyacente 5",
        categoria: "",
        answer: "hiuh",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "d2e166c0-3770-43bf-aa0d-753737dfd9bb",
        question: "Puesto adyacente 2",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "15f7f606-80a2-4877-8ffc-bf3fb9bf17b5",
        question: "Puesto adyacente 4",
        categoria: "",
        answer: "hui",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "de84bbc2-5138-46f4-b8b7-7a67da910a46",
        question: "Puesto adyacente 6",
        categoria: "",
        answer: "uih",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "9b8b83d3-552f-485f-93f1-083f0a333a55",
        question: "Puesto adyacente 8",
        categoria: "",
        answer: "hh",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "5ae53fd5-2754-4f5e-8984-88dc7ef41f82",
        question: "Puesto adyacente 3",
        categoria: "",
        answer: "hiu",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "2f5f8f2c-b65c-4c9f-80b4-d54d209e811c",
        question: "Puesto adyacente 7",
        categoria: "",
        answer: "iuhu",
        json: null,
        created_at: "2024-07-02 17:21:44.283",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
    ],
    answersDivergentFuture: [
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "da6f5456-625d-4d21-80f0-e4abcbe57127",
        question: "Puesto ambicioso altamente divergente 1",
        categoria: "",
        answer: "nuhiu",
        json: null,
        created_at: "2024-07-02 17:21:50.441",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "fe741b9d-990e-40f3-a027-4b5f1d529d25",
        question: "Puesto ambicioso altamente divergente 2",
        categoria: "",
        answer: "huih",
        json: null,
        created_at: "2024-07-02 17:21:50.441",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "4272c88d-0ea1-46c7-b8ad-a74924ad1091",
        question: "Puesto altamente divergente 1",
        categoria: "",
        answer: "uh",
        json: null,
        created_at: "2024-07-02 17:21:50.441",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "a5b7c430-18e6-4ad8-86d0-8e5e5f0e1c81",
        question: "Puesto altamente divergente 2",
        categoria: "",
        answer: "uih",
        json: null,
        created_at: "2024-07-02 17:21:50.441",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
      {
        email: "juan.mora@tecmilenio.mx",
        idpregunta: "16021785-cf74-4b54-9561-19012067b927",
        question: "Puesto altamente divergente 3",
        categoria: "",
        answer: "iuh",
        json: null,
        created_at: "2024-07-02 17:21:50.441",
        source: "life-design",
        tipo_respuesta: "RespuestaSimple",
      },
    ],
  };

  const nodes = [
    {
      id: "root",
      position: { x: 0, y: 350 },
      data: { label: "Tu puesto actual" },
      sourcePosition: "right",
      targetPosition: "right",
    },
    {
      id: "root-label",
      position: { x: 0, y: 385 },
      data: { label: "Tú actualmente" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "white", border: "none"},
      className: "node-label"
    },
    {
      id: "divergent-label",
      position: { x: 200, y: 0 },
      data: { label: "Escenarios altamente divergentes" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(229,146,186)", border: "none"},
      className: "node-label"
    },
    {
      id: "direct-label",
      position: { x: 200, y: 315 },
      data: { label: "Escenarios directos" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(38,208,124)", border: "none"},
      className: "node-label"
    },
    {
      id: "firstAdj-label",
      position: { x: 200, y: 165 },
      data: { label: "Escenarios adyacentes" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(164,203,244)", border: "none"},
      className: "node-label"
    },
    {
      id: "secondAdj-label",
      position: { x: 200, y: 465 },
      data: { label: "Escenarios adyacentes" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(164,203,244)", border: "none"},
      className: "node-label"
    },
  ];
  const edges = [
    {
      id: `root-direct`,
      source: `root`,
      target: `direct-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
    {
      id: `root-firstAdj`,
      source: `root`,
      target: `firstAdj-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
    {
      id: `root-secondAdj`,
      source: `root`,
      target: `secondAdj-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
    {
      id: `root-divergent`,
      source: `root`,
      target: `divergent-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
  ];

  // Directo
  const directFuture = [];
  // Ambicioso Directo
  const dirAmbFuture = [];
  // Adyacente 1
  const firstAdj = [];
  // Ambicioso Adyacente 1
  const firstAmbAdj = [];
  // Adyacente 2
  const secondAdj = [];
  // Ambicioso Adyacente 2
  const secondAmbAdj = [];
  // Divergente
  const divergent = [];
  // Divergente Ambicioso
  const divergentAmb = [];

  // Select and order direct + ambitious direct scenarios
  for (let i = 0; i < sampleData.answersDirectFuture.length; i++) {
    // sample: "Puesto directo 1" or "Puesto ambicioso directo 2"
    // The second word determines the type and the last word the order
    const question = sampleData.answersDirectFuture[i].question.split(" ");
    const type = question[1];
    console.log(type);
    if (type === "directo") {
      directFuture[+question[question.length - 1] - 1] =
        sampleData.answersDirectFuture[i];
    } else {
      dirAmbFuture[+question[question.length - 1] - 1] =
        sampleData.answersDirectFuture[i];
    }
  }

  // Create nodes and edges for direct scenarios
  for (let i = 0; i < directFuture.length; i++) {
    nodes.push({
      id: `direct-${i}`,
      position: { x: (i + 1) * 200, y: 350 },
      data: { label: directFuture[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(38,208,124)" },
    });
    if (i > 0) {
      edges.push({
        id: `direct-${i - 1}-${i}`,
        source: `direct-${i - 1}`,
        target: `direct-${i}`,
        sourcePosition: "right",
        targetPosition: "left",
        style: { strokeWidth: "4px", stroke: "white" },
      });
    }
  }

  // Create nodes and edges for ambitious direct scenarios
  // Link first ambitious node to last direct node
  nodes.push({
    id: `dirAmb-${0}`,
    position: { x: (directFuture.length + 1) * 200, y: 350 },
    data: { label: dirAmbFuture[0].question },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `dirAmb-0-0`,
    source: `direct-${directFuture.length - 1}`,
    target: `dirAmb-0`,
    style: { strokeWidth: "4px", stroke: "white" },
    markerEnd: { type: MarkerType.Arrow, color: "white" },
  });

  // Create the rest of the ambitious direct nodes
  for (let i = 1; i < dirAmbFuture.length; i++) {
    nodes.push({
      id: `dirAmb-${i}`,
      position: { x: (directFuture.length + i + 1) * 200, y: 350 },
      data: { label: dirAmbFuture[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `dirAmb-${i - 1}-${i}`,
      source: `dirAmb-${i - 1}`,
      target: `dirAmb-${i}`,
      style: { strokeWidth: "4px", stroke: "rgb(239,206,42)" },
      animated: true,
    });
  }

  // Select and order first adjacent + first ambitious adjacent scenarios
  for (let i = 0; i < sampleData.answersAdjacentFuture.length; i++) {
    // sample: "Puesto adyacente 1" or "Puesto ambicioso adyacente 2"
    // The second word determines the type and the last word the order
    const question = sampleData.answersAdjacentFuture[i].question.split(" ");
    const type = question[1];
    console.log(type);
    if (type === "adyacente") {
      firstAdj[+question[question.length - 1] - 1] =
        sampleData.answersAdjacentFuture[i];
    } else {
      firstAmbAdj[+question[question.length - 1] - 1] =
        sampleData.answersAdjacentFuture[i];
    }
  }

  // Create nodes and edges for first adjacent scenarios
  for (let i = 0; i < firstAdj.length; i++) {
    nodes.push({
      id: `firstAdj-${i}`,
      position: { x: (i + 1) * 200, y: 200 },
      data: { label: firstAdj[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(164,203,244)" },
    });
    if (i > 0) {
      edges.push({
        id: `firstAdj-${i - 1}-${i}`,
        source: `firstAdj-${i - 1}`,
        target: `firstAdj-${i}`,
        style: { strokeWidth: "4px", stroke: "white" },
      });
    }
  }

  // Create nodes and edges for first ambitious adjacent scenarios
  // Link first ambitious node to last adjacent node
  nodes.push({
    id: `firstAmbAdj-${0}`,
    position: { x: (firstAdj.length + 1) * 200, y: 200 },
    data: { label: firstAmbAdj[0].question },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `firstAmbAdj-0-0`,
    source: `firstAdj-${firstAdj.length - 1}`,
    target: `firstAmbAdj-0`,
    style: { strokeWidth: "4px", stroke: "white" },
  });

  // Create the rest of the ambitious direct nodes
  for (let i = 1; i < firstAmbAdj.length; i++) {
    nodes.push({
      id: `firstAmbAdj-${i}`,
      position: { x: (firstAdj.length + i + 1) * 200, y: 200 },
      data: { label: firstAmbAdj[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `firstAmbAdj-${i - 1}-${i}`,
      source: `firstAmbAdj-${i - 1}`,
      target: `firstAmbAdj-${i}`,
      style: { strokeWidth: "4px", stroke: "white" },
    });
  }

  // Select and order second adjacent + second ambitious adjacent scenarios
  for (let i = 0; i < sampleData.answersSecondAdjacentFuture.length; i++) {
    // sample: "Puesto adyacente 1" or "Puesto ambicioso adyacente 2"
    // The second word determines the type and the last word the order
    const question =
      sampleData.answersSecondAdjacentFuture[i].question.split(" ");
    const type = question[1];
    console.log(type);
    if (type === "adyacente") {
      secondAdj[+question[question.length - 1] - 1] =
        sampleData.answersSecondAdjacentFuture[i];
    } else {
      secondAmbAdj[+question[question.length - 1] - 1] =
        sampleData.answersSecondAdjacentFuture[i];
    }
  }

  // Create nodes and edges for second adjacent scenarios
  for (let i = 0; i < secondAdj.length; i++) {
    nodes.push({
      id: `secondAdj-${i}`,
      position: { x: (i + 1) * 200, y: 500 },
      data: { label: secondAdj[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(164,203,244)" },
    });
    if (i > 0) {
      edges.push({
        id: `secondAdj-${i - 1}-${i}`,
        source: `secondAdj-${i - 1}`,
        target: `secondAdj-${i}`,
        style: { strokeWidth: "4px", stroke: "white" },
      });
    }
  }

  // Create nodes and edges for second ambitious adjacent scenarios
  // Link first ambitious node to last adjacent node
  nodes.push({
    id: `secondAmbAdj-${0}`,
    position: { x: (secondAdj.length + 1) * 200, y: 500 },
    data: { label: secondAmbAdj[0].question },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `secondAmbAdj-0-0`,
    source: `secondAdj-${secondAdj.length - 1}`,
    target: `secondAmbAdj-0`,
    style: { strokeWidth: "4px", stroke: "white" },
  });

  // Create the rest of the second ambitious adjacent nodes
  for (let i = 1; i < secondAmbAdj.length; i++) {
    nodes.push({
      id: `secondAmbAdj-${i}`,
      position: { x: (secondAdj.length + i + 1) * 200, y: 500 },
      data: { label: secondAmbAdj[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `secondAmbAdj-${i - 1}-${i}`,
      source: `secondAmbAdj-${i - 1}`,
      target: `secondAmbAdj-${i}`,
      style: { strokeWidth: "4px", stroke: "white" },
    });
  }

  // Select and order divergent + ambitious divergent scenarios
  for (let i = 0; i < sampleData.answersDivergentFuture.length; i++) {
    // sample: "Puesto altamente divergente 1" or "Puesto ambicioso altamente divergente 2"
    // The second word determines the type and the last word the order
    const question = sampleData.answersDivergentFuture[i].question.split(" ");
    const type = question[1];
    console.log(type);
    if (type === "altamente") {
      divergent[+question[question.length - 1] - 1] =
        sampleData.answersDivergentFuture[i];
    } else {
      divergentAmb[+question[question.length - 1] - 1] =
        sampleData.answersDivergentFuture[i];
    }
  }

  // Create nodes and edges for divergent scenarios
  for (let i = 0; i < divergent.length; i++) {
    nodes.push({
      id: `divergent-${i}`,
      position: { x: (i + 1) * 200, y: 50 },
      data: { label: divergent[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(229,146,186)" },
    });
    if (i > 0) {
      edges.push({
        id: `divergent-${i - 1}-${i}`,
        source: `divergent-${i - 1}`,
        target: `divergent-${i}`,
        style: { strokeWidth: "4px", stroke: "white" },
        animated: true,
      });
    }
  }

  // Create nodes and edges for ambitious divergent scenarios
  // Link first ambitious node to last divergent node
  nodes.push({
    id: `divergentAmb-${0}`,
    position: { x: (divergent.length + 1) * 200, y: 50 },
    data: { label: divergentAmb[0].question },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `divergentAmb-0-0`,
    source: `divergent-${divergent.length - 1}`,
    target: `divergentAmb-0`,
    style: { strokeWidth: "4px", stroke: "white" },
    animated: true,
  });

  // Create the rest of the ambitious divergent nodes
  for (let i = 1; i < divergentAmb.length; i++) {
    nodes.push({
      id: `divergentAmb-${i}`,
      position: { x: (divergent.length + i + 1) * 200, y: 50 },
      data: { label: divergentAmb[i].question },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `divergentAmb-${i - 1}-${i}`,
      source: `divergentAmb-${i - 1}`,
      target: `divergentAmb-${i}`,
      style: { strokeWidth: "4px", stroke: "white" },
      animated: true,
    });
  }

  // Create label for ambitious nodes
  nodes.push({
    id: "divergentAmb-label",
    position: { x: ((divergent.length + divergentAmb.length) * 200), y: 10 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  },
  {
    id: "directAmb-label",
    position: { x: ((directFuture.length + dirAmbFuture.length) * 200), y: 315 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  },
  {
    id: "firstAdjAmb-label",
    position: { x: ((firstAdj.length + firstAmbAdj.length) * 200), y: 165 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  },
  {
    id: "secondAdjAmb-label",
    position: { x: ((secondAdj.length + secondAmbAdj.length) * 200), y: 465 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  })

  return (
    <div className="escenarios-layout">
      <ReactFlow nodes={nodes} edges={edges} />
    </div>
  );
}

export default Escenarios;
