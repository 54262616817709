import "./Breadcrumbs.css"

export default function Breadcrumbs(props) {

    const breadcrumbs = props.prev.map(breadcrumb => {
        return <>
            <a className="breadcrumb" href={breadcrumb.link}>{breadcrumb.prev}</a>
            <span>/</span>
        </>
    })

    return(
        <div className="breadcrumbs">
            {breadcrumbs}
            <p className="breadcrumb-current">{props.current}</p>
        </div>
    )
}