import React from 'react'
import "./Sidebar.css"
import { IconButton } from '@mui/material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

function Sidebar(props) {

  const { 
    goToStep, 
    currentStep,
    prevModule,
    nextModule
  } = props

  const steps = []
  for (let i = 0; i < props.steps.length; i++) {
    steps.push(
      <div key={i} className={`step step-${props.steps[i].status} ${currentStep.current === props.steps[i].id ? 'current' : ''}`} onClick={() => {
        if (props.steps[i].status === 'complete') {
          goToStep(props.steps[i])
        }
      }}>
        <div className={`index index-${props.steps[i].status}`}>{+i+1}</div>
        <p>{props.steps[i].title}</p>
      </div>
    )
  }
  return (
    <div className='sidebar'>
      <div className='sidebar-title'>
        <p>{props.title.split(": ")[1]}</p>
        <div className='sidebar-buttons'>
          <IconButton 
            onClick={() => {
              prevModule()
            }}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <IconButton 
            disabled={props.steps[props.steps.length - 1]?.status === 'complete' ? false : true}
            onClick={() => {
              if (props.steps[props.steps.length - 1]?.status === 'complete') {
                nextModule()
              }
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </div>
      </div>
      {steps}
      </div>
  )
}

export default Sidebar