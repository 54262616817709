import React, { useState } from 'react';
import { EmptySidebar } from '../../components';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import './ProyectoDeAprendizaje.css'
import ProyectoAprendizaje from '../ProyectoAprendizaje/ProyectoAprendizaje';
import Tendencias from '../Tendencias/Tendencias';

const ProyectoDeAprendizaje = () => {

    const [currentTab, setCurrentTab] = useState(0);

    const options = [
        {
            id: 0,
            name: "Mi propósito de vida",
            icon: <ArrowForwardIosRoundedIcon />,
            onClick: () => setCurrentTab(0),
        },
        {
            id: 1,
            name: "Tendencias aspiraciones profesionales",
            icon: <ArrowBackIosRoundedIcon />,
            onClick: () => setCurrentTab(1),
        },
    ];

    const content = (currentTab) => {
        switch (currentTab) {
            case 0:
                return (
                    <ProyectoAprendizaje />
                );
            case 1:
                return (
                    <Tendencias />
                );
            default:
                return (
                    <div>
                        <h1>Proyecto de Aprendizaje</h1>
                    </div>
                );
        }
    }

    return (
        <div className="aprendizaje-layout">
            <div className="col-25">
                <EmptySidebar
                    currentOption={currentTab}
                    options={options}
                    title="Proyecto de Aprendizaje"
                />
            </div>
            <div className="col-75">
                {content(currentTab)}
            </div>
        </div>
    );
}

export default ProyectoDeAprendizaje;