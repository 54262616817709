import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";

import Button from "../../components/Button/Button";

import "./GymPage.css";
import pivotea from "../../assets/newsletter.png";

const GymPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();

    useEffect(() => {
        if (!isLoggedIn) {
            console.log("context - not logged in");
            //1. Check localstorage
            if (JSON.parse(window.localStorage.getItem("PIVOTEA_LOGIN")) !== null) {
                console.log("Login found in localstorage");
                //User is logged in
                const decodedToken = jwtDecode(
                    JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN"))
                );
                if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
                    //Token has expired, logout user
                    console.log("expired token");
                    setAuthUser(null);
                    setIsLoggedIn(false);
                    window.localStorage.clear();
                    navigate("/login");
                } else {
                    //Valid token
                    console.log("valid token");
                    setAuthUser({
                        name: JSON.parse(window.localStorage.getItem("PIVOTEA_NAME")),
                        lastName: JSON.parse(
                            window.localStorage.getItem("PIVOTEA_LASTNAME")
                        ),
                        id: JSON.parse(window.localStorage.getItem("PIVOTEA_UID")),
                        token: JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN")),
                        current_module: JSON.parse(window.localStorage.getItem("PIVOTEA_CURRENT_MODULE")),
                        current_step: JSON.parse(window.localStorage.getItem("PIVOTEA_CURRENT_STEP"))
                    });
                    setIsLoggedIn(true);
                }
            } else {
                console.log("no storage")
                //User is not logged in,
                navigate("/login");
            }
        }
    }, []);

    const subscribe = () => {
        console.log(email);
    };

    const ShowMoreSection = (props) => {
        const [showMore, setShowMore] = useState(false);

        return (
            <div>
                {showMore ? (
                    <div>
                        <p>{props.children}</p>
                        <div className="showMore" onClick={() => setShowMore(false)}>Mostrar menos</div>
                    </div>
                ) : (
                    <div className="showMore" onClick={() => setShowMore(true)}>Mostrar más</div>
                )}
            </div>
        );
    }

    return (
        <div className="gym-page">
            <div className="gym-banner">
                <h1>GYM PROFESIONAL</h1>
                <p>
                    En este apartado podrás fortalecer tu camino hacia el empleo, brindándote recursos para mejorar la empleabilidad. <br /> Desde la optimización de tu currículum hasta consejos prácticos para entrevistas, te equipamos para destacarte en el mercado laboral.
                </p>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>1. CV de impacto</h2>
                    <p>
                        Tu currículum tiene un aproximado de 5 a 10 segundos para impresionar y ser de interés por eso es necesario que sea de impacto.
                        A continuación tenemos una serie de recomendaciones para seguir a la hora de crearlo.
                    </p>
                    <p>
                        1. Tu CV tiene que estar dirigido y diseñado para el trabajo o la vacante que vas a aplicar.
                    </p>

                    <ShowMoreSection>
                        <p>2. Fíjate en los criterios de las vacantes, si cumples con 80% o más aplica sin problema.</p>
                        <p>3. No mientas en tu CV, no pongas nada que no sea real.</p>
                        <p>4. Agrega palabras clave, aquellas que en la vacante resaltaron.</p>
                        <p>5. Cuida la estructura de tu CV que se entienda, es mejor algo sencillo pero entendible.</p>
                       <p> 6. Primero coloca lo más reciente en tu experiencia laboral.</p>
                        <p>7. Muestra porque tus últimos trabajos serían relevantes para la vacante que estás aplicando.</p>
                        <p>8. Mantenlo corto, un CV en una hoja es el ideal, si son más posiblemente los pasen por alto.</p>
                        <p>9. Demuestra cómo has avanzado de puesto, experiencia o rango.</p>
                        <p>10. Incluye links a otras redes sociales laborales tuyas donde te puedan conocer mejor (esto solo si tienes, sino no es necesario crearlas).</p>
                        <p>11. Excluye información relevante, piensa que es de interés para la vacante, a ningún trabajo le va a importar dónde hiciste la prepa.</p>
                        <p>12. Envialo en PDF y en word, siempre mandalo en las 2 opciones porque hay reclutadores que usan ciertas aplicaciones que necesitan sean documentos normales.</p>
                    </ShowMoreSection>

                </div>
                <div className="gym-column-info">
                    <h3>
                        Herramientas
                    </h3>
                    <p>
                        Si no sabes donde realizar tus CVS aquí te dejamos algunas herramientas a plataformas.
                    </p>
                    <a className="gym-btn" target="_blank" href="http://www.livecareer.es/curriculum-online">CV ONLINE &rarr;</a>
                    <a className="gym-btn" target="_blank" href="http://www.canva.com/">PLANTILLAS CANVA &rarr;</a>
                    <a className="gym-btn" target="_blank" href="https://plantillas-curriculum.com/">OTRAS PLANTILLAS &rarr;</a>
                </div>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>2. Entrevista laboral</h2>
                    <p>
                        Una entrevista de trabajo es una técnica de selección personal para encontrar al candidato ideal a un puesto laboral. Y en el Laboratorio de Innovación creamos una herramienta de IA (Inteligencia Artificial) que te permite vivir simulaciones de entrevistas acorde al perfil o vacante que estás aplicando.
                    </p>
                </div>
                <div className="gym-column-info">
                    <h3>
                        PRUEBA LA HERRAMIENTA
                    </h3>
                    <p>
                        Te presentamos a Karen. Quien será tu ayudante para mejorar y sobresalir en tu próxima entrevista laboral. <br /> !Haznos saber tu opinión de la herramienta!
                    </p>
                    <a className="gym-btn" target="_blank" href="https://digitalnao.github.io/chatbot/">KAREN IA &rarr;</a>
                </div>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>3. Bolsas de trabajo</h2>
                    <p>
                        Una bolsa de trabajo es un espacio en el cual se registran una serie de ofertas laborales, y a su vez lo mismo sucede con las personas que buscan empleao. Las bolsas de trabajo pueden ser privadas o públicas.
                    </p>
                </div>
                <div className="gym-column-info">
                    <h3>
                        PÚBLICAS
                    </h3>
                    <p>
                    Estas bolsas de trabajo suelen ser gratuitas y solo necesitan un correo electrónico para registrarte y llenar información con tus datos, también suele tener un apartado con mayores filtros pero que tienen un costo extra.
                    </p>
                    <a className="gym-btn" target="_blank" href="https://occ.com.mx/">OCC MUNDIAL &rarr;</a>
                    <a className="gym-btn" target="_blank" href="https://mx.computrabajo.com/">COMPUTRABAJO &rarr;</a>
                    <a className="gym-btn" target="_blank" href="https://indeed.com">INDEED &rarr;</a>

                    <h3>PRIVADAS</h3>
                    <p>1. Puede ser la bolsa de empleo de tu universidad, posiblemente tendrá un costo para darte de alta.</p>
                    <p>2. Bolsas de trabajo de la misma organización generalmente internacionales
(HPE, HPI,NESTLE).</p>

                    <h3>REDES SOCIALES</h3>
                    <p>Hoy en día existen varias redes sociales profesionales que ayudan en la ardua tarea de buscar empleo.</p>
                    <p>Plataforma muy útil para crear los contactos profesionales pertinentes, y además, en ella se publican múltiples ofertas de empleo.</p>
                    <a className="gym-btn" target="_blank" href="https://linkedin.com">LINKEDIN &rarr;</a>
                    <p>Comunidad para profesionales creativos en español. A través de la plataforma de Domestika, los creativos pueden compartir sus proyectos, conectar con otros creativos y encontrar empleo.</p>
                    <a className="gym-btn" target="_blank" href="https://domestika.com">DOMESTIKA &rarr;</a>
                    <p>Es la primera red social de networking mundial para mujeres profesionales. A través de la plataforma, es posible cambiar o encontrar empleo, formarse y desarrollarse profesionalmente.</p>
                </div>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>4. Automatizar tu búsqueda de trabajo</h2>
                    <p>
                        Buscar un nuevo empleo implica una inversión importante de tiempo y energía, casi igual a lo que puedes gastar trabajando regularmente. Por lo tanto, realizar esta labora implica una dosis considerable de organización.
                    </p>
                </div>
                <div className="gym-column-info">
                    <h3>
                        HERRAMIENTAS
                    </h3>
                    <p>
                        Activa las alertas de LinkedIn:
                    </p>
                    <a className="gym-btn" target="_blank" href="https://linkedin.com">ALERTAS LINKEDIN &rarr;</a>
                    <p>En Domestika verifica que la casilla de Notificaciones de Empleo este palomeada en Domestika.</p>
                    <p>Para revisar esto visita la Configuración de tu Cuenta > Notificaciones > Empleo.</p>
                    <a className="gym-btn" target="_blank" href="https://domestike.com">DOMESTIKA &rarr;</a>
                    <p>Para CompuTrabajo, activa alertas de los trabajos que buscas yendo a Tu Perfil > Mis Alertas y añade las alertas de trabajos que quieras.</p>
                    <a className="gym-btn" target="_blank" href="https://mx.computrabajo.com/">COMPUTRABAJO &rarr;</a>
                </div>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>5. Palabras clave</h2>
                    <p>
                        Las palabras clave pueden ayudarnos en una doble vía: a encntra empleo proactivamente y a ser encontrados.
                    </p>
                    <p>¿Qué grupos de palabras clave me interesan?</p>
                    <p>- Campo o industra de interés.</p>

                    <ShowMoreSection>
                        <p>- Ubicación geográfica de interés</p>
                        <p>- Nombre del cargo o puesto</p>
                        <p>- Habilidades</p>
                        <p>- Herramientas específicas de la industria</p>
                        <p>- Nombres de empresas</p>
                        <p>- Tipo de trabajo</p>
                    </ShowMoreSection>
                </div>
                <div className="gym-column-info">
                    <h3>
                        ¿CÓMO ENCONTRAR LAS PALABRAS CLAVE MÁS RELEVANTES?
                    </h3>
                    <p>
                        Ejercicio 1:
                    </p>
                    <p>1. Realiza una búsqueda de vacantes en Linkedin según tu ocupación, por ejemplo: ‘Licenciatura en Mercadotecnia’.</p>
                    
                    <ShowMoreSection>
                        <p>2. Revisa las vacantes que te interesen y anota las palabras clave que se repiten.</p>
                        <p>3. Revisa las descripciones y resalta palabras nuevas con las describen algún atributo en la vacante, por ejemplo, el nombre de un software, una región geográfica, etc.</p>
                        <p>4. Realiza una lista con las palabras clave que resaltaste y activalas como alertas.</p>
                    </ShowMoreSection>
                    <p>
                        Ejercicio 2:
                    </p>
                    <p>1. Realiza una búsqueda de trabajos en Linkedin.</p>

                    <ShowMoreSection>
                        <p>2. Escoge las diez empresas donde te gustaría trabajar.</p>
                        <p>3. Revisa las vacantes que más te llaman la atención.</p>
                        <p>4. Revisa los nombres con los que describen las vacantes que te interesan.</p>
                        <p>5. Realiza una lista con los nombres de las palabras clave que encontraste y activalas como alertas.</p>
                    </ShowMoreSection>
                </div>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>6. Rangos salariales</h2>
                    <p>
                        Conoce el rango en los que se encuentra lo que tu realizas. Te dará más poder en la toma de decisión de tu propio trabajo y te permitirá:
                    </p>
                    <p>1. Ver si una vacante está arriba o abajo de mi expectativa económica.</p>
                    <ShowMoreSection>
                        <p>2. Conocer el rango en el que puedo negociar una oferta económica (el 80% de los empleadores está dispuesto a hacerlo).</p>
                        <p>3. Reconocer si ese tipo de trabajo es suficiente para tu sustento o pensar en otro giro.</p>
                    </ShowMoreSection>
                </div>
                <div className="gym-column-info">
                    <h3>
                        DONDE REVISAR
                    </h3>
                    <p>
                        Aquí puedes revisar cualquier oferta de trabajo y la tasa salarial por año, mes, quincena, semana, día o incluso hora.
                    </p>
                    <a className="gym-btn" target="_blank" href="https://talent.com">TALENT.COM &rarr;</a>
                    <p>Aquí puedes buscar por distintas categorías y giros, así como por puestos específicos.</p>
                    <a className="gym-btn" target="_blank" href="https://mx.computrabajo.com/">COMPUTRABAJO &rarr;</a>
                    <p>Aquí podrás encontrar el comparativo de salario con puestos diversos y oficios.</p>
                    <a className="gym-btn" target="_blank" href="https://misalario.org/">MISALARIO.ORG &rarr;</a>
                </div>
            </div>
            <div className="gym-columns">
                <div className="gym-column-header">
                    <h2>7. Branding personal en LinkedIn</h2>
                    <p>
                        Explora el arte de crear una marca personal convincente que se alinee con tu identidad y metas profesionales. ​
                    </p>
                </div>
                <div className="gym-column-info">
                    <h3>
                        PERSONAL BRANDING
                    </h3>
                    <p>
                        Descubre de qué trata y crea una marca personal sólida en LinkedIn que impulse tu perfil profesional.
                    </p>
                    <a className="gym-btn" target="_blank" href="https://utmedu.sharepoint.com/:p:/r/sites/VicerrectoriaIII/_layouts/15/Doc.aspx?sourcedoc=%7B3667273E-9F63-41CB-B7AC-EA0B3BB2ECF9%7D&file=Branding%20Personal%20en%20LinkedIn.pptx&action=edit&mobileredirect=true">Conoce más &rarr;</a>
                </div>
            </div>
            <div className="newsletter-container">
                <div className="newsletter-text">
                    <h4>BIENVENID@ A PIVOTEA</h4>
                    <p>
                        Si tienes alguna duda, comentario o simplemente quieres saludar,
                        envía un correo a:{" "}
                        <a href="mailto:competencias@servicios.tecmilenio.mx" target="_blank">
                            competencias@servicios.tecmilenio.mx
                        </a>
                    </p>
                </div>
                <div className="newsletter-cta">
                    <img src={pivotea} alt="Pivotea" />
                    <div className="newsletter-form">
                        <h4>FORMA PARTE DE FUTUROS PILOTOS</h4>
                        <div>
                            <p>Comparte tu email con nosotros</p>
                            <input
                                type="email"
                                value={email}
                                placeholder="usuario@email.com"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <Button
                            type="outline"
                            corners="rounded"
                            text="Suscribirme"
                            size="sm"
                            onClick={subscribe}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GymPage;