import React, { useState, useEffect, useContext, useRef } from "react";

const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider(props) {
  const authUser = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const setAuthUser = (value) => {
    authUser.current = value;
  }

  const value = {
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
