import "./NavBar.css";
import logo from "../../assets/pivotea-logo.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function NavBar(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const [hidden, setHidden] = useState("hidden");

  /*
  useEffect(() => {
    if (!isLoggedIn) {
      //Check localStorage
      if (JSON.parse(window.localStorage.getItem("PIVOTEA_LOGIN")) !== null) {
        setAuthUser({
          name: JSON.parse(window.localStorage.getItem("PIVOTEA_NAME")),
          lastName: JSON.parse(window.localStorage.getItem("PIVOTEA_LASTNAME")),
          id: JSON.parse(window.localStorage.getItem("PIVOTEA_UID")),
          token: JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN")),
        });
        setIsLoggedIn(true);
      }
    }
  }, []);
  */

  const onOpen = () => {
    setHidden("");
  };

  const onClose = () => {
    setHidden("hidden");
  };

  const onLogout = () => {
    console.log("logout clicked");
    window.localStorage.clear();
    setIsLoggedIn(false);
    setAuthUser(null);
    window.location.reload();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="navbar">
      <a href="/">
        <img src={logo} className="logo" alt="Pivotea" />
      </a>
      <div className="menu-items">

        {isLoggedIn ? (
          <>
            <nav>
              <ul>
                <li>
                  <a href="/miespacio">Mi espacio</a>
                </li>
                <li>
                  <a href="/gym-profesional">Gym Profesional</a>
                </li>
              </ul>
            </nav>
            <a aria-describedby={id} onClick={handleClick}>
              <Avatar
                sx={{ bgcolor: "var(--secondary-dark)", color: "var(--primary)" }}
                alt={isLoggedIn ? authUser.current.name : "-"}
              >
                {isLoggedIn ? authUser.current.name[0] : "-"}
              </Avatar>
            </a>
          </>
        ) : (
          <>
            <a className="nav-link" href="/login">Iniciar sesión</a>
            <a className="nav-link" href="/signup">Registro</a>
          </>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <a href="/miespacio" className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }}>
            Mi espacio de trabajo
          </a>
          <a href="/diario-digital" className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }}>
            Mi diario digital
          </a>
          <a className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }} onClick={onLogout}>
            <ExitToAppRoundedIcon /> Cerrar sesión
          </a>
        </Popover>
      </div>
      <div className="mobile-toggle">
        <button onClick={onOpen}>
          <MenuRoundedIcon />
        </button>
      </div>
      <div className={`menu-overlay ${hidden}`} onClick={onClose}></div>
      <div className={`mobile-menu ${hidden}`}>
        <button onClick={onClose}>
          <CloseRoundedIcon />
        </button>
        <nav>
          <ul>
            <li>
              <a href="/miespacio">Mi espacio</a>
            </li>
            <li>
              <a href="/">Gym Profesional</a>
            </li>
          </ul>
        </nav>
        <div className="mobile-profile">
          <Avatar
            sx={{ bgcolor: "var(--secondary-dark)", color: "var(--primary)" }}
            alt={isLoggedIn ? authUser.current.name : "-"}
          >
            {isLoggedIn ? authUser.current.name[0] : "-"}
          </Avatar>
          <p>{isLoggedIn ? authUser.current.name : "-"}</p>
        </div>
        <a className="logout-mobile" onClick={onLogout}>
          <ExitToAppRoundedIcon /> Cerrar sesión
        </a>
      </div>
    </div>
  );
}
