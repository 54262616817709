import React from "react";
import logo from "../../assets/pivotea-logo-green.png";
import banner from "../../assets/green-banner.png";
import lightning from "../../assets/lightning.png";
import "./ProyectoAprendizaje.css";

function ProyectoAprendizaje() {
  const sampleData = {
    name: "CATALINA MADELEINE ZEPEDA BLOUINE",
    purpose:
      "Mi propósito de vida es hacer que otras personas, hombres y mujeres, puedan vivir una vida más feliz a pesar de las adversidades. Sembrar amor propio y propósito de vida en cada uno.",
    strengths: [
      {
        title: "Honestidad",
        description:
          "Eres una persona muy íntegra y auténtica; te presentas ante los demás de forma sincera y te haces responsable de tus actos.",
      },
      {
        title: "Amabilidad",
        description:
          "Haces cosas buenas por los demás; eres generoso; tienes compasión y entrega.",
      },
      {
        title: "Amor",
        description:
          "Eres una persona cálida y auténtica con los demás; valoras crear relaciones de cercanía e intimidad con otras personas.",
      },
      {
        title: "Amor por el aprendizaje",
        description:
          "Sueles encontrar maneras de profundizar tu conocimiento y tus experiencias; buscas nuevas oportunidades para aprender.",
      },
      {
        title: "Curiosidad",
        description:
          "Te gusta explorar; te interesan las actividades, ideas y personas nuevas; estás abierto a tener nuevas experiencias.",
      },
    ],
    opportunities: [
      {
        title: "Ocupacional",
        statement:
          "Tener futuras oportunidades laborales que me permitan crecer el alcance del impacto",
        justification:
          "porque estoy emprendiendo pero también necesito un trabajo que me de estabilidad económica.",
      },
      {
        title: "Intelectual",
        statement: "Seguir desarrollando mis habilidades",
        justification:
          "porque me gusta aprender y quiero saber más cosas para poder ser la coach que quiero ser.",
      },
      {
        title: "Física",
        statement: "Tener un mejor balance o gestión de tiempo",
        justification:
          "porque se la importancia de estar bien físicamente. Me refiero puntualmente a mi alimentación y ejercicio.",
      },
    ],
  };

  const strengths = [];
  sampleData.strengths.forEach((el) => {
    strengths.push(
      <>
        <li>{el.title}</li>
        <span>{el.description}</span>
      </>
    );
  });

  const opportunities = [];
  sampleData.opportunities.forEach((el) => {
    opportunities.push(
      <div className="opportunity">
        <div className="opp-header">{el.title}</div>
        <span>{el.statement}</span>
        <p>{el.justification}</p>
      </div>
    );
  });
  return (
    <div className="proyecto-layout">
      <div className="proyecto-header">
        <img src={logo} className="proyecto-logo" alt="Pivotea" />
        <p>Proyecto de aprendizaje</p>
      </div>
      <div className="proyecto-banner">
        <h3>{sampleData.name}</h3>
        <p>{sampleData.purpose}</p>
        <img src={banner} alt="" />
      </div>
      <div className="proyecto-columns">
        <div className="strengths">
          <img src={lightning} alt="" />
          <h4>Fortalezas de carácter</h4>
          <ol>{strengths}</ol>
        </div>
        <div className="opportunities">
          <h4>
            Portafolio de vida:
            <br />
            Mis áreas de mejora
          </h4>
          {opportunities}
        </div>
      </div>
    </div>
  );
}

export default ProyectoAprendizaje;
