import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import "./Signup.css";
import logo from "../../assets/pivotea-logo.png";

export default function Signup() {
  const navigate = useNavigate();
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/api" : "http://localhost:3501";

  useEffect(() => {
    //1. Check context for user data
    if (isLoggedIn) {
      navigate("/");
    } else {
      //Check localstorage
      if (JSON.parse(window.localStorage.getItem("PIVOTEA_LOGIN") !== null)) {
        //User found, now verify JWT
        const decodedToken = jwtDecode(
          JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN"))
        );
        if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
          //Token has expired, logout user
          setAuthUser(null);
          setIsLoggedIn(false);
          window.localStorage.clear();
        } else {
          //Valid token
          setAuthUser({
            name: JSON.parse(window.localStorage.getItem("PIVOTEA_NAME")),
            lastName: JSON.parse(
              window.localStorage.getItem("PIVOTEA_LASTNAME")
            ),
            id: JSON.parse(window.localStorage.getItem("PIVOTEA_UID")),
            token: JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN")),
            current_module: JSON.parse(
              window.localStorage.getItem("PIVOTEA_CURRENT_MODULE")
            ),
            current_step: JSON.parse(
              window.localStorage.getItem("PIVOTEA_CURRENT_STEP")
            ),
          });
          setIsLoggedIn(true);
          navigate("/");
        }
      }
    }
  }, []);

  const onSignup = () => {
    axios
      .post(url + `/auth/signup`, {
        email: email,
        password: pass,
        name: name,
        last_name: lastName,
      })
      .then((response) => {
        setIsLoggedIn(true);
        window.localStorage.setItem(
          "PIVOTEA_LOGIN",
          JSON.stringify(isLoggedIn)
        );
        window.localStorage.setItem(
          "PIVOTEA_UID",
          JSON.stringify(response.data.data.user.id_user)
        );
        window.localStorage.setItem(
          "PIVOTEA_NAME",
          JSON.stringify(response.data.data.user.name)
        );
        window.localStorage.setItem(
          "PIVOTEA_LASTNAME",
          JSON.stringify(response.data.data.user.name)
        );
        window.localStorage.setItem(
          "PIVOTEA_TOKEN",
          JSON.stringify(response.data.token)
        );
        window.localStorage.setItem(
          "PIVOTEA_CURRENT_MODULE",
          JSON.stringify(response.data.data.user.current_module)
        );
        window.localStorage.setItem(
          "PIVOTEA_CURRENT_STEP",
          JSON.stringify(response.data.data.user.current_step)
        );
        setAuthUser({
          name: response.data.data.user.name,
          lastName: response.data.data.user.name,
          id: response.data.data.user.id_user,
          token: response.data.token,
          current_module: response.data.data.user.current_module,
          current_step: response.data.data.user.current_step,
        });
        navigate("/");
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  useEffect(() => {
    if (email && pass && name && lastName && terms) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }
  , [email, pass, name, lastName, terms]);

  return (
    <div className="signup-layout">
      <img src={logo} alt="Pivotea" />
      <h1>Regístrate</h1>
      <div className="signup-form">
        <form>
          <input
            type="text"
            placeholder="Nombre(s)"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Apellidos"
            id="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Contraseña"
            id="password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <div className="terms">
          <input type="checkbox" id="terms" onChange={e => setTerms(true)}/>
          <label htmlFor="terms">
            Acepto y estoy de acuerdo con el{" "}
            <a className="link" href="https://tecmilenio.mx/es/politicas-de-privacidad">
              aviso de privacidad
            </a>
          </label>
          </div>
          {error ? (
            <div className="error-msg">
              <p>El correo ingresado ya está asociado a una cuenta.</p>
            </div>
          ) : null}
        </form>
        <button className="btn btn-filled" onClick={onSignup} disabled={disabled}>
          Registrarse
        </button>
      </div>
      <p>
        ¿Ya tienes un perfil?{" "}
        <a className="link" href="#" onClick={() => navigate("/login")}>
          Inicia Sesión
        </a>
      </p>
    </div>
  );
}
