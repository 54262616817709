import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import "./Login.css";
import logo from "../../assets/pivotea-logo-dark.png";
import { useTimer } from 'react-timer-hook';
import Timer from "../../components/Timer/Timer";

export default function Login() {
  const navigate = useNavigate();
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);
  const [recovery, setRecovery] = useState(false);
  const [success, setSuccess] = useState(false);
  const [timer, setTimer] = useState(false);
  const time = useRef(null);

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL + "/api"
    : "http://localhost:3501";

    const [disabledBtn, setDisabledBtn] = useState(false)

  useEffect(() => {
    //1. Check context for user data
    if (isLoggedIn) {
      navigate("/");
    } else {
      //Check localstorage
      if (JSON.parse(window.localStorage.getItem("PIVOTEA_LOGIN") !== null)) {
        //User found, now verify JWT
        const decodedToken = jwtDecode(
          JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN"))
        );
        if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
          //Token has expired, logout user
          setAuthUser(null);
          setIsLoggedIn(false);
          window.localStorage.clear();
        } else {
          //Valid token
          setAuthUser({
            name: JSON.parse(window.localStorage.getItem("PIVOTEA_NAME")),
            lastName: JSON.parse(
              window.localStorage.getItem("PIVOTEA_LASTNAME")
            ),
            id: JSON.parse(window.localStorage.getItem("PIVOTEA_UID")),
            token: JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN")),
            current_module: JSON.parse(
              window.localStorage.getItem("PIVOTEA_CURRENT_MODULE")
            ),
            current_step: JSON.parse(
              window.localStorage.getItem("PIVOTEA_CURRENT_STEP")
            ),
          });
          setIsLoggedIn(true);
          navigate("/");
        }
      }
    }
  }, []);

  const onLogin = () => {
    axios
      .post(url + `/auth/login`, {
        email: email,
        password: pass,
      })
      .then((response) => {
        //console.log(response.data.data.user);
        setIsLoggedIn(true);
        window.localStorage.setItem(
          "PIVOTEA_LOGIN",
          JSON.stringify(isLoggedIn)
        );
        window.localStorage.setItem(
          "PIVOTEA_UID",
          JSON.stringify(response.data.data.user.id_user)
        );
        window.localStorage.setItem(
          "PIVOTEA_NAME",
          JSON.stringify(response.data.data.user.name)
        );
        window.localStorage.setItem(
          "PIVOTEA_LASTNAME",
          JSON.stringify(response.data.data.user.name)
        );
        window.localStorage.setItem(
          "PIVOTEA_TOKEN",
          JSON.stringify(response.data.token)
        );
        window.localStorage.setItem(
          "PIVOTEA_CURRENT_MODULE",
          JSON.stringify(response.data.data.user.current_module)
        );
        window.localStorage.setItem(
          "PIVOTEA_CURRENT_STEP",
          JSON.stringify(response.data.data.user.current_step)
        );
        setAuthUser({
          name: response.data.data.user.name,
          lastName: response.data.data.user.name,
          id: response.data.data.user.id_user,
          token: response.data.token,
          current_module: response.data.data.user.current_module,
          current_step: response.data.data.user.current_step,
        });
        navigate("/");
      })
      .catch((error) => {
        setError(true);
      });
  };

  const onRecovery = () => {
    setDisabledBtn(true)
    axios
      .post(url + `/auth/generater-recovery-token`, {
        email: email,
      })
      .then((response) => {
        setSuccess(true);
        setError(false);
        const timerEnd = new Date();
        timerEnd.setSeconds(timerEnd.getSeconds() + 90);
        time.current = timerEnd;
        setTimer(true);
        //console.log(response.data);
        setDisabledBtn(false)
      })
      .catch((error) => {
        //console.log(error);
        setDisabledBtn(false)
        setError(true);
      });
     /* Prueba
      const timerEnd = new Date();
      timerEnd.setSeconds(timerEnd.getSeconds() + 90);
      time.current = timerEnd;
      setTimer(true);*/
  
  };

  const timerCallback = () => {
    setTimer(false);
  }

  return (
    <div className="login-layout">
      <img src={logo} alt="Pivotea" />
      {!recovery && (
        <>
          <h1>Iniciar Sesión</h1>
          <div className="login-form">
            <form>
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Contraseña"
                id="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
              {error ? (
                <div className="error-msg">
                  <p>El correo y/o contraseña es incorrecto.</p>
                </div>
              ) : null}
            </form>
            <button
              className="link"
              onClick={() => {
                setRecovery(true);
              }}
            >
              ¿Olvidaste la contraseña?
            </button>
            <button className="btn btn-filled" onClick={onLogin}>
              Iniciar sesión
            </button>
          </div>
          <p>
            ¿Eres nuevo en este sitio?{" "}
            <span className="link" onClick={() => navigate("/signup")}>
              Regístrate
            </span>
          </p>
        </>
      )}
      {recovery && (
        <>
          <div className="login-form">
            <h3>Recuperar contraseña</h3>
            <p className="password-msg">
              Escribe tu correo registrado, si existe se enviará un correo con
              el enlace para recuperar tu cuenta.
            </p>
            <form>
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error ? (
                <div className="error-msg">
                  <p>Hubo un error al intentar restablecer tu contraseña. Revisa que el correo que ingresaste sea correcto.</p>
                </div>
              ) : null}
              {success ? (
                <div className="success-msg">
                  <p>Se ha enviado un correo con los siguientes pasos.</p>
                </div>
              ) : null}
            </form>
            {timer && (
              <Timer expiryTimestamp={time.current} callback={timerCallback}/>
            )}
            {!timer && (
              <button className="btn btn-filled" disabled={disabledBtn} onClick={onRecovery}>
                Recuperar tu contraseña
              </button>
            )}
            {timer && (
              <button disabled className="btn btn-disabled">
                Recuperar tu contraseña
              </button>
            )}
          </div>
          <button className="link" onClick={() => {setError(false); setRecovery(false)}}>
            {" "}
            Regresar
          </button>
        </>
      )}
    </div>
  );
}