import React from 'react'
import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import "../Login/Login.css";
import logo from "../../assets/pivotea-logo-dark.png";

function Recovery() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = useRef(null);
    const [password, setPassword] = useState('');
    const [confPass, setConfPass] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL + "/api"
    : "http://localhost:3501";

    useEffect(() => {
      if(searchParams.has("token")) {
        token.current = searchParams.get("token");
      } else {
        navigate("/login");
      }
    }, [])

    const onRecovery = () => {
        if(password === confPass) {
            axios.post(url + "/auth/reset", {token: token.current, password: password}).then(response => {
                setSuccess(response.data.message);
                setTimeout(() => {
                  navigate("/login")
                }, 3000)
                setError("")
            }).catch(error => {
              console.log(error.response.data.error.message)
                setError(error.response.data.error.message)
            })
        }
        else {
            setError("Las contraseñas no coinciden.")
        }
    }
    
  return (
    <div className="login-layout">
      <img src={logo} alt="Pivotea" />
          <div className="login-form">
            <h3>Recuperar contraseña</h3>
            <p className="password-msg">
              Ingresa una nueva contraseña
            </p>
            <form>
                <label for="password">Contraseña</label>
              <input
                type="password"
                placeholder=""
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label for="passConf">Confirmar contraseña</label>
              <input
                type="password"
                placeholder=""
                id="confPass"
                value={confPass}
                onChange={(e) => setConfPass(e.target.value)}
              />
              {error.length > 0 ? (
                <div className="error-msg">
                  <p>{error}</p>
                </div>
              ) : null}
              {success.length > 0 ? (
                <div className="success-msg">
                  <p>{success}</p>
                </div>
              ) : null}
            </form>
            
            {!success && (
              <button className="btn btn-filled" onClick={onRecovery}>
                Restablecer contraseña
              </button>
            )}

          </div>
          <p>
            <span className="link" onClick={() => navigate("/login")}>Iniciar Sesión
            </span>
          </p>
    </div>
  )
}

export default Recovery