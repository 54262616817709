import React from 'react';
import { useTimer } from 'react-timer-hook';

function Timer({ expiryTimestamp, callback }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => callback() });


  return (
        <p className='password-msg'>Puedes volver a intentarlo en {minutes}:{seconds > 9 ? seconds : `0${seconds}`}</p>
  );
}

export default Timer;